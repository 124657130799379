import api from "@/aygulyazilim/libs/axios";
import { router } from "@/plugins/1.router";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    isLoggedIn: localStorage.getItem(import.meta.env.VITE_APP_TITLE + "-token")
      ? true
      : false,
    user: {
      uuid: "",
      name: localStorage.getItem(import.meta.env.VITE_APP_TITLE + "-userName"),
      email: localStorage.getItem(
        import.meta.env.VITE_APP_TITLE + "-userEmail"
      ),
      telefon: "",
      personel: "",
      programci: false,
      admin: false,
      yetkiler: [],
    },
    userRaw: {
      uuid: "",
      name: localStorage.getItem(import.meta.env.VITE_APP_TITLE + "-userName"),
      email: localStorage.getItem(
        import.meta.env.VITE_APP_TITLE + "-userEmail"
      ),
      telefon: "",
      personel: "",
      programci: false,
      admin: false,
      yetkiler: [],
    },
  }),
  actions: {
    async logout() {
      const response = (await api.get("auth/logout")).data;

      localStorage.removeItem(import.meta.env.VITE_APP_TITLE + "-token");
      this.user = JSON.parse(JSON.stringify(this.userRaw));
      router.push({ name: "login" });
    },

    async ftechUser() {
      const res = await api.get("auth/user");

      if (res != undefined) this.user = res.data;

      // await new Promise((resolve) => setTimeout(resolve, 2000));
      this.isLoggedIn = true;
    },
  },
});
